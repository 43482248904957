<template>
	<div>
		<div class="introduce">
			{{$config.appName}}致力于广大商家提供跨境商品采购批发服务，低成本采购，100%正品保障，全球精选商品、进口奶粉、进口化妆品、进口保健品、进口生活用品等海外正品授权。一手货源，全程商品溯源。
		</div>
		<div class="iphone-box">
			<div class="left"><img :src="indexPic" alt="" /></div>
			<i class="el-icon-d-arrow-left jiantou"></i>
			<div class="right"><!-- <img hidden :src="code" alt="" /> --> 还没有上架</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'iPhone',
	components: {},
	data() {
		return {
			indexPic: require('@/assets/images/index_' + this.$config.appvesion + '.jpg'),
			// code: require('@/assets/images/appStore_' + this.$config.appvesion + '.png'),
		};
	},
	mounted() {},
	methods: {}
};
</script>

<style lang="less" scoped>
.introduce {
	text-indent: 2em;
	line-height: 2;
	padding: 20px 10px 0;
}
.iphone-box {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 30px 100px;
	.left{
		width: 50%;
		img{
			width: 100%;
		}
	}
	.jiantou {
		font-size: 50px;
		color: #f60;
		margin: 0 40px;
	}
	.right {
		background: #fff;
		padding: 10px;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
		width: 260px;
		height: 260px;
		img {
			width: 100%;
			height: auto;
		}
	}
}
</style>
